import React from 'react'
import useTrans from '@hooks/useTrans'
import { ProdottoType, ArticoloType } from 'utils/types'
import { Label, ShareButton, WContainer } from '@components/atoms'
import { ArticoliCorrelati, ImageHead, Page, ProdottiCorrelati } from '@components/organisms'
import styles from './ArticoloTemplate.module.sass'
import { PlaceholderReact } from '@components/molecules'
import Link from 'next/link'

interface Props {
  articolo: ArticoloType
  correlati?: [ArticoloType]
  prodottiConsigliati?: [ProdottoType]
  defaultContent?: any
}

const ArticoloTemplate = (props: Props) => {
  const { articolo, correlati, prodottiConsigliati, defaultContent } = props

  const t = useTrans()

  return (
    <Page pagina={articolo.pagina} item={articolo}>
      <div className={styles.articoloTemplate}>
        <ImageHead
          breadcrumbs={articolo.pagina.breadcrumbs}
          image={articolo.mainImage}
          title={articolo.titolo}
          description={articolo.descrizione}
        >
          <div className={styles.headContent}>
            {!!articolo.autore?.label && (
              <Link legacyBehavior href="/i-nostri-collaboratori">
                <a className={styles.author}>
                  <div dangerouslySetInnerHTML={{ __html: articolo.autore.label }} />
                </a>
              </Link>
            )}
            {articolo.temi?.length > 0 && (
              <div className={styles.tags}>
                {articolo.temi.map((x) => (
                  <Label key={x.pk} label={x.nome} color="white" bordered />
                ))}
              </div>
            )}
          </div>
          <div className={styles.shareTop}>
            <ShareButton item={articolo} />
          </div>
        </ImageHead>
        <div className={styles.main}>
          <WContainer variant="xs">
            <PlaceholderReact
              contentTypeId={articolo.model}
              objectId={articolo.pk}
              pkey="content"
              serverSideContent={defaultContent}
              size="xs"
            />
            <div className={styles.shareBottom}>
              <ShareButton item={articolo} />
            </div>
          </WContainer>
        </div>
        <div className={styles.bottom}>
          {prodottiConsigliati?.length > 0 && true && (
            <div className={styles.prodottiConsigliati}>
              <WContainer>
                <ProdottiCorrelati
                  item={{
                    name: t('Consigliati da noi'),
                    description: t(
                      'Su mymellinshop.it puoi trovare gli alimenti più adatti alla crescita del tuo bambino.'
                    ),
                    children: prodottiConsigliati.map(({ node: { prodotto } }) => ({
                      pk: prodotto.pk,
                      name: prodotto.titolo,
                      description: prodotto.descrizione,
                      image: prodotto.mainImage,
                      url: prodotto.link,
                      ctaLabel: prodotto.cta,
                      blank: prodotto.blank,
                    })),
                  }}
                />
              </WContainer>
            </div>
          )}
          {correlati?.length > 0 && (
            <div className={styles.articoliCorrelati}>
              <WContainer>
                <ArticoliCorrelati
                  item={{
                    name: t('Potrebbe interessarti anche'),
                    children: correlati.map((c) => ({ articolo: c, pk: c.pk })),
                  }}
                />
              </WContainer>
            </div>
          )}
        </div>
      </div>
    </Page>
  )
}

export default ArticoloTemplate
