import React from 'react'
import { ArticoloType, CategoriaType } from 'utils/types'
import { ShareButton, WContainer } from '@components/atoms'
import { Page, SettimanaHead, Timeline } from '@components/organisms'
import styles from './SettimanaTemplate.module.sass'
import { PlaceholderReact } from '@components/molecules'

interface Props {
  articolo: ArticoloType
  categoria: CategoriaType
  defaultContent?: any
}

const SettimanaTemplate = (props: Props) => {
  const { articolo, categoria, defaultContent } = props

  const settimane =
    categoria?.articoli.map((a) => ({
      numero: a.numeroSettimana,
      url: a.url,
    })) || []

  return (
    <Page pagina={articolo.pagina} item={articolo}>
      <div className={styles.settimanaTemplate}>
        <SettimanaHead
          breadcrumbs={articolo.pagina.breadcrumbs}
          numeroSettimana={articolo.numeroSettimana}
          title={articolo.titolo}
          description={articolo.descrizione}
        >
          <div className={styles.shareTop}>
            <ShareButton item={articolo} />
          </div>
        </SettimanaHead>
        <div className={styles.main}>
          <WContainer variant="xs">
            <PlaceholderReact
              contentTypeId={articolo.model}
              objectId={articolo.pk}
              pkey="content"
              serverSideContent={defaultContent}
              size="xs"
            />
            <div className={styles.shareBottom}>
              <ShareButton item={articolo} />
            </div>
          </WContainer>
        </div>
        {settimane.length > 0 && (
          <div className={styles.bottom}>
            <WContainer>
              <Timeline
                settimane={settimane}
                next={categoria.next}
                prev={categoria.prev}
                className={styles.timeline}
                settimanaCorrente={articolo.numeroSettimana}
              />
            </WContainer>
          </div>
        )}
      </div>
    </Page>
  )
}

export default SettimanaTemplate
