import { initializeApollo } from '@gql/client'
import { ARTICOLI, ARTICOLO } from 'utils/queries'
import {
  useArticoliCorrelatiQuery,
  useProdottiConsigliatiQuery,
  useCategoriaSettimanaLazyQuery,
  useVisitaArticoloMutation,
} from 'gql/graphql'
import ArticoloTemplate from '@components/templates/ArticoloTemplate/ArticoloTemplate'
import { ArticoloType } from 'utils/types'
import { TIPO_2 } from 'utils/settings'
import SettimanaTemplate from '@components/templates/SettimanaTemplate/SettimanaTemplate'
import { useEffect } from 'react'
import { api as pagebuilderApi } from 'react-pagebuilder/services/api'
import { ArticleJsonLd } from 'next-seo'
import { BRAND, LOGO_URL } from 'utils/settings'
import { NextWrapper } from 'data-fetching-wrapper'

interface Props {
  articolo: ArticoloType
  defaultContent?: any
}

function ArticoloDetail({ articolo, defaultContent }: Props) {
  const { data: correlatiData } = useArticoliCorrelatiQuery({
    variables: {
      slug: articolo.slug,
    },
    fetchPolicy: 'cache-first',
  })

  const { data: prodottiConsigliatiData } = useProdottiConsigliatiQuery({
    variables: {
      articoloId_In: [articolo.pk],
    },
    fetchPolicy: 'cache-first',
  })

  const [getCategoria, { data: categoriaData }] = useCategoriaSettimanaLazyQuery({
    variables: {
      slugTree: articolo?.categoria?.slugTree,
    },
    fetchPolicy: 'cache-first',
  })

  const [visitaArticolo] = useVisitaArticoloMutation({ variables: { pk: articolo.pk } })

  function initCategoriaSettimana() {
    getCategoria()
  }

  useEffect(() => {
    if (articolo.tipo === TIPO_2) {
      initCategoriaSettimana()
    } else {
      visitaArticolo()
    }
  }, [])

  return (
    <>
      {articolo.tipo === TIPO_2 ? (
        <SettimanaTemplate
          articolo={articolo}
          categoria={categoriaData?.categoria}
          defaultContent={defaultContent}
        />
      ) : (
        <ArticoloTemplate
          articolo={articolo}
          correlati={correlatiData?.articolo?.correlati}
          prodottiConsigliati={prodottiConsigliatiData?.prodottiConsigliatiArticoli?.edges}
          defaultContent={defaultContent}
        />
      )}
      <ArticleJsonLd
        url={articolo.url}
        title={articolo.titolo}
        images={[articolo.mainImage]}
        authorName={articolo.autore?.titolo || ''}
        publisherName={BRAND}
        publisherLogo={LOGO_URL}
        description={articolo.descrizione}
        datePublished=""
        dateModified=""
      />
    </>
  )
}

export const getStaticPaths = NextWrapper.getStaticPaths(async () => {
  const client = initializeApollo()

  const {
    data: { articoli },
  } = await client.query({
    query: ARTICOLI,
  })

  const paths = articoli.edges.map((x) => ({
    params: { slug: x.node.slug },
  }))

  return {
    paths,
    fallback: 'blocking',
  }
}, '/a/[slug]')

export const getStaticProps = NextWrapper.getStaticProps(
  async ({ params: { slug }, preview, previewData }) => {
    const headers = preview && previewData?.headers ? { cookie: previewData.headers } : {}

    const client = initializeApollo()

    const {
      data: { articolo },
    } = await client.query({
      query: ARTICOLO,
      variables: { slug, preview: preview },
      context: {
        headers,
      },
    })

    if (!articolo) {
      return {
        notFound: true,
      }
    }

    let defaultContent = null
    try {
      defaultContent = await pagebuilderApi.getPagebuilder({
        contentTypeId: articolo.model,
        objectId: articolo.pk,
        key: 'content',
        edit_mode: false,
      })
    } catch (err) {
      console.log(err)
    }

    return {
      props: { articolo, key: articolo.pk, defaultContent },
      revalidate: 60 * 2,
    }
  },
  '/a/[slug]'
)

export default ArticoloDetail
